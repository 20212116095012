import React, { useEffect, useState } from "react";
import { Route, Routes as BaseRoutes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Products from "./pages/Products";
import Product from "./pages/Product";
import Contact from "./pages/Contact";

export default function Routes() {
  const [data, setData] = useState();
  const fetchData = async () => {
    const posts = await fetch("/api/fetchNotion").then((res) =>
      res.json().then((data) => setData(data.results))
    );
    console.log("fetching");
  };

  useEffect(() => {
    if (!data) fetchData();
  }, []);

  return (
    <BaseRoutes>
      <Route path="/" element={<Home data={data} />} />
      <Route path="/contatti" element={<Contact />} />
      <Route path="/about" element={<About />} />
      <Route path="blog">
        <Route index element={<Products data={data} />} />
        <Route path=":postId" element={<Product data={data} />} />
      </Route>
    </BaseRoutes>
  );
}
