import React from "react";
import { Col, Row, Card, Button } from "antd";
import { useParams, useNavigate } from "react-router";
import { ArrowRightOutlined } from "@ant-design/icons";

export default function Home(props) {
  const navigate = useNavigate();
  console.log(props.data);
  const { Meta } = Card;
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return (
    <>
      <Row
        gutter={16}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflowX: "hidden",
          marginBottom: 100,
          marginTop: 50,
        }}
      >
        <Col xs={{ span: 24 }} md={{ span: 14 }} lg={{ span: 6 }}>
          <img
            style={{ width: "100%", height: "auto" }}
            src="https://i.ibb.co/fDTb1xF/silvia2.png"
            // src="https://i.ibb.co/Vg9pK5v/silvia.png"
          ></img>
        </Col>
        <Col
          xs={{ span: 24 }}
          md={{ span: 14 }}
          lg={{ span: 8 }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col xs={{ span: 22 }}>
            <h1 style={{ fontSize: 48 }}>Dott.ssa Silvia Mattiello</h1>
            <h2 style={{ color: "#5e5e5e" }}>
              Psicologa, Esperta in Neuropsicologia Clinica e Psicoterapeuta
              Cognitivo-Comportamentale
            </h2>

            <Button onClick={() => navigate("/about")} type="link">
              Scopri di più su di me <ArrowRightOutlined />
            </Button>
          </Col>
        </Col>
      </Row>

      <Row justify="center" align="middle" style={{ marginBottom: 50 }}>
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
          xs={{ span: 24 }}
          md={{ span: 20 }}
          lg={{ span: 14 }}
        >
          <h2
            style={{
              textAlign: "left",
              width: "100%",
              padding: 10,
              marginBottom: 50,
              fontSize: 28,
            }}
          >
            Di cosa mi occupo?
          </h2>
          <Row
            gutter={16}
            style={{
              width: "100%",
              display: "block",
            }}
          >
            <Col xs={{ span: 24 }} lg={{ span: 18 }}>
              <Row align="middle" justify="space-between">
                <Col xs={{ span: 12 }} lg={{ span: 8 }}>
                  <img
                    style={{ width: "100%", height: "auto" }}
                    src="https://i.ibb.co/WpD4CN3/undraw-Landscape-photographer-c4d7.png"
                  ></img>
                </Col>
                <Col
                  style={{ textAlign: "right" }}
                  xs={{ span: 12 }}
                  lg={{ span: 10 }}
                >
                  <h2 style={{ fontSize: 22, marginBottom: 10 }}>
                    Neuropsicologia
                  </h2>
                  <p style={{ fontSize: 18, color: "#5e5e5e" }}>
                    Consulenza sui disturbi cognitivi e comportamentali,
                    Valutazione e Riabilitazione Neuropsicologica, Stimolazione
                    Cognitiva
                  </p>
                  <Button
                    onClick={() =>
                      navigate(
                        `/blog/Neuropsicologia?id=1828e7b2-cd86-4552-b514-4da8df7dcc0a`
                      )
                    }
                    type="link"
                  >
                    Continua a leggere <ArrowRightOutlined />
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col
              style={{ float: "right", marginTop: 50 }}
              xs={{ span: 24 }}
              lg={{ span: 18 }}
            >
              <Row align="middle" justify="space-between">
                <Col
                  style={{ textAlign: "left" }}
                  xs={{ span: 12 }}
                  lg={{ span: 10 }}
                >
                  <h2 style={{ fontSize: 22, marginBottom: 10 }}>
                    Supporto Psicologico
                  </h2>
                  <p style={{ fontSize: 18, color: "#5e5e5e" }}>
                    Problematiche di ansia, depressione, stress, fobie e
                    attacchi di panico, Training di Rilassamento Progressivo e
                    Training Autogeno, Training di Assertività
                  </p>
                  <Button
                    onClick={() =>
                      navigate(
                        `/blog/supportopsicologico?id=404066e1-5e71-4274-a910-ca51b035bcd7`
                      )
                    }
                    type="link"
                  >
                    Continua a leggere <ArrowRightOutlined />
                  </Button>
                </Col>
                <Col xs={{ span: 12 }} lg={{ span: 8 }}>
                  <img
                    style={{ width: "100%", height: "auto" }}
                    src="https://i.ibb.co/tzxdQzk/undraw-medicine-b1ol.png"
                  ></img>
                </Col>
              </Row>
            </Col>
            <Col
              style={{ float: "left", marginTop: 50 }}
              xs={{ span: 24 }}
              lg={{ span: 18 }}
            >
              <Row align="middle" justify="space-between">
                <Col xs={{ span: 12 }} lg={{ span: 8 }}>
                  <img
                    style={{ width: "100%", height: "auto" }}
                    src="https://i.ibb.co/71vpZvH/undraw-Love-is-in-the-air-4uud.png"
                  ></img>
                </Col>
                <Col
                  style={{ textAlign: "right" }}
                  xs={{ span: 12 }}
                  lg={{ span: 10 }}
                >
                  <h2 style={{ fontSize: 22, marginBottom: 10 }}>
                    Supporto al Caregiver
                  </h2>
                  <p style={{ fontSize: 18, color: "#5e5e5e" }}>
                    Supporto psicologico ai familiari, Psicoeducazione, Gestione
                    dei disturbi comportamentali e Ristrutturazione funzionale
                    del contesto domestico
                  </p>
                  <Button
                    onClick={() =>
                      navigate(
                        `/blog/supportoalcaregiver?id=5de11779-ecce-4ddd-a966-e578be7d5985`
                      )
                    }
                    type="link"
                  >
                    Continua a leggere <ArrowRightOutlined />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
          xs={{ span: 24 }}
          md={{ span: 20 }}
          lg={{ span: 14 }}
        >
          <h2
            style={{
              textAlign: "left",
              width: "100%",
              padding: 10,
              fontSize: 18,
            }}
          >
            Ultimi post
          </h2>
          <Row
            gutter={16}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {props.data &&
              props.data.slice(0, 3).map((e) => {
                return (
                  // <Col
                  //   onClick={() =>
                  //     navigate(
                  //       `/blog/${e.properties.Name.title[0].plain_text.replace(
                  //         / /g,
                  //         ""
                  //       )}-?id=${e.id}`
                  //     )
                  //   }
                  //   xs={{ span: 24 }}
                  //   md={{ span: 12 }}
                  //   lg={{ span: 8 }}
                  // >
                  //   <Card
                  //     style={{
                  //       width: "100%",
                  //       marginBottom: 20,
                  //     }}
                  //   >
                  //     <p style={{ fontSize: 20 }}>
                  //       {e.properties.Name.title[0].plain_text}
                  //     </p>
                  //     <span>
                  //       {e.properties.content.rich_text[0].plain_text.slice(
                  //         0,
                  //         120
                  //       )}
                  //     </span>
                  //   </Card>
                  // </Col>
                  <Col
                    onClick={() =>
                      navigate(
                        `/blog/${e.properties.Name.title[0].plain_text
                          .replace(/ /g, "")
                          .replace("?", "")}?id=${e.id}`
                      )
                    }
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                  >
                    <Col
                      style={{
                        height: 150,
                        padding: 0,
                        borderRadius: 5,
                        overflow: "hidden",
                        marginBottom: 20,
                        backgroundImage: `url(${e.properties.image.files[0].name})`,
                        backgroundSize: "cover",
                        cursor: "pointer",
                      }}
                    >
                      <Col
                        style={{
                          height: 150,
                          backdropFilter: "blur(1px)",
                          position: "relative",
                        }}
                      >
                        <Col style={{ height: 150 }} className="blog"></Col>
                        <p
                          style={{
                            fontSize: 20,
                            color: "white",
                            zIndex: "999",
                            position: "absolute",
                            bottom: "10%",
                          }}
                        >
                          {e.properties.Name.title[0].plain_text}
                        </p>
                        <span
                          style={{
                            color: "white",
                            zIndex: "999",
                            position: "absolute",
                            top: "5%",
                          }}
                        >
                          {new Date(e.last_edited_time).toLocaleDateString(
                            "it-IT",
                            options
                          )}
                        </span>
                      </Col>
                    </Col>
                  </Col>
                );
              })}
          </Row>
        </Col>
      </Row>
    </>
  );
}
