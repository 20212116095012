import { Link, useRoutes } from "react-router-dom";
import { Col, Row, Card, Button } from "antd";
import { useNavigate } from "react-router";

import routes from "./routes";
import Routes from "./Routes";

function App() {
  const navigate = useNavigate();
  const routeResult = useRoutes(routes);
  const options = {
    year: "numeric",
  };
  return (
    <>
      <Row justify="center" align="middle" style={{ padding: 10 }}>
        <Col
          xs={{ span: 24 }}
          md={{ span: 20 }}
          lg={{ span: 16 }}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Link style={{ color: "black", fontSize: 22 }} to="/">
            <strong>Silvia Mattiello</strong>
          </Link>

          <Col>
            <Button
              style={{ fontSize: 18 }}
              onClick={() => {
                navigate("/blog");
              }}
              type="text"
            >
              Blog
            </Button>
            <Button
              onClick={() => {
                navigate("/contatti");
              }}
              style={{ fontSize: 18 }}
              type="text"
            >
              Contatti
            </Button>
          </Col>
        </Col>
      </Row>

      <main style={{ minHeight: "85vh" }}>
        {/* You can use by useRoutes like this (I prefer it): */}
        {/* {routeResult} */}
        {/* Or use by defining it */}
        <Routes />
      </main>
      <Col style={{ color: "grey", textAlign: "center" }}>
        ©Silvia Mattiello {new Date().toLocaleDateString("it-IT", options)}
      </Col>
    </>
  );
}

export default App;
