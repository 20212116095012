import React from "react";
import { Col, Row, Timeline } from "antd";

export default function About() {
  return (
    <>
      <Row
        gutter={16}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflowX: "hidden",
        }}
      >
        <Col xs={{ span: 24 }} md={{ span: 14 }} lg={{ span: 6 }}>
          <img
            style={{ width: "100%", height: "auto" }}
            src="https://i.ibb.co/mGLWDC0/avata3.png"
          ></img>
        </Col>

        <Col
          xs={{ span: 24 }}
          md={{ span: 14 }}
          lg={{ span: 8 }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col xs={{ span: 22 }}>
            <h1 style={{ fontSize: 48 }}>Su di me</h1>
            <h3 style={{ color: "#5e5e5e", lineHeight: 2 }}>
              Benvenuti! Sono Silvia Mattiello, Psicologa, Esperta in
              Neuropsicologia Clinica e Psicoterapeuta Cognitivo-Comportamentale
              in Formazione. <br></br> Lavoro in libera professione nel
              territorio padovano e online ed iscritta all'Ordine degli
              Psicologi del Veneto (num. 12518 - sez. A). <br></br> Mi occupo di
              malattie neurologiche e neurodegenerative, RSA, disabilità,
              progettazione e attuazione di Interventi psico-educativi.{" "}
              <br></br> Ho una{" "}
              <mark style={{ backgroundColor: "#C0FFC8" }}>
                Laurea Triennale
              </mark>{" "}
              in Scienze Psicologiche dello Sviluppo e dell'Educazione (2016,
              Università di Padova), una{" "}
              <mark style={{ backgroundColor: "#FFF9C0" }}>
                Laurea Magistrale
              </mark>{" "}
              in Neuroscienze e Riabilitazione Neuropsicologica (2019,
              Università di Padova) e un{" "}
              <mark style={{ backgroundColor: "#FAC7C7" }}>
                {" "}
                Master II Livello
              </mark>{" "}
              in Neuropsicologia Clinica (2021, Università di Padova).
              Attualmente, frequento una
              <mark style={{ backgroundColor: "#ffe0c1" }}>
                {" "}
                Scuola di Specializzazione in Psicoterapia.
              </mark>{" "}
            </h3>
          </Col>
        </Col>
      </Row>
      <Row style={{ marginTop: 50 }} gutter={32} justify="center">
        <Col
          xs={{ span: 22 }}
          md={{ span: 12 }}
          lg={{ span: 22 }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Timeline mode="alternate">
            <Timeline.Item label="2016">
              Laurea Triennale in Scienze Psicologiche dello Sviluppo e
              dell’Educazione, Università degli Studi di Padova
            </Timeline.Item>
            <Timeline.Item label="2017 - 2022" color="green">
              Educatrice a tempo pieno Fondazione Irpea, Istituti Riuniti
              Padovani di Educazione e Assistenza
            </Timeline.Item>
            <Timeline.Item label="2019">
              Laurea Magistrale in Neuroscienze e Riabilitazione
              Neuropsicologica, Università degli Studi di Padova
            </Timeline.Item>
            <Timeline.Item label="2019" color="green">
              Psicologa presso Azienda ospedaliera università di Padova
            </Timeline.Item>

            <Timeline.Item label="2021">
              Master II Livello in Neuropsicologia Clinica,Università degli
              Studi di Padova
            </Timeline.Item>
            <Timeline.Item label="2022 - oggi" color="green">
              Neuropsicologa Azienda Ospedaliera Universitaria di Padova,
              Ospedale Sant'Antonio
            </Timeline.Item>
            <Timeline.Item label="2022 - oggi" color="green">
              Psicologa Centro Clinico Stella Polare
            </Timeline.Item>
            <Timeline.Item label="2022 - oggi" color="green">
              Psicologa Residenza Villa Altichiero, Casa di Cura per non
              autosufficienti - RSA
            </Timeline.Item>

            <Timeline.Item label="in corso">
              <b>in corso</b> | Scuola di Specializzazione in Psicoterapia, in
              corso
            </Timeline.Item>
          </Timeline>
        </Col>
      </Row>
    </>
  );
}
