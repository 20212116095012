import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Col, Row, Card } from "antd";
import PostHtml from "../components/PostHtml";

export default function Product(props) {
  const [queryParams] = useSearchParams();
  const id = queryParams.get("id");
  console.log(props.data);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const [post, setPost] = useState({});

  useEffect(() => {
    if (props.data) {
      const filter = props.data.filter((e) => e.id == id);
      setPost(filter[0]);
      console.log(id, filter[0]);
    }
  }, [props.data]);

  return (
    <>
      {post?.id && (
        <>
          {post.properties.link?.url ? (
            <PostHtml post={post}></PostHtml>
          ) : (
            <Row gutter={16} style={{ justifyContent: "center" }}>
              <Col
                xs={{ span: 22 }}
                md={{ span: 22 }}
                lg={{ span: 13 }}
                style={{
                  backgroundImage: `url(${post.properties.image.files[0].name})`,
                  backgroundSize: "cover",
                  height: "40vh",
                }}
              ></Col>
              <Col
                xs={{ span: 22 }}
                md={{ span: 22 }}
                lg={{ span: 13 }}
                style={{
                  fontSize: 24,
                  margin: 20,
                }}
              >
                <h1>{post.properties.Name.title[0].plain_text}</h1>
              </Col>
              <Col
                xs={{ span: 22 }}
                md={{ span: 22 }}
                lg={{ span: 13 }}
                style={{
                  fontSize: 16,
                  color: "darkgrey",
                  marginBottom: 20,
                }}
              >
                <span style={{ marginRight: 20 }}>Silvia Mattiello</span>
                <span>
                  {new Date(post.last_edited_time).toLocaleDateString(
                    "it-IT",
                    options
                  )}
                </span>
              </Col>

              <Col xs={{ span: 22 }} md={{ span: 22 }} lg={{ span: 13 }}>
                <p
                  style={{
                    fontSize: 20,
                    lineHeight: 2,
                  }}
                >
                  {post.properties.content.rich_text[0].plain_text}
                </p>
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
}
