import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Col, Row, Skeleton } from "antd";

export default function PostHtml(props) {
  const [html, setHtml] = useState("");
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const post = props.post;

  useEffect(() => {
    if (post.properties.link.url) {
      const url = post.properties.link.url.slice(-32);
      fetch(`https://notion-page-to-html-api.vercel.app/html?id=${url}`)
        .then(function (response) {
          return response.text();
        })
        .then(function (e) {
          console.log("e", e);
          setHtml(e);
        })
        .catch(function (err) {
          console.warn("Something went wrong.", err);
        });
    }
  }, [post]);

  return (
    <>
      {html ? (
        <Row justify="center">
          <Col
            xs={{ span: 22 }}
            md={{ span: 22 }}
            lg={{ span: 13 }}
            style={{
              fontSize: 16,
              color: "darkgrey",
              paddingLeft: 10,
            }}
          >
            <span style={{ marginRight: 20 }}>Silvia Mattiello</span>
            <span>
              {new Date(post.last_edited_time).toLocaleDateString(
                "it-IT",
                options
              )}
            </span>
          </Col>
          <Col style={{}} xs={{ span: 22 }} md={{ span: 22 }} lg={{ span: 13 }}>
            <Row
              className="product"
              dangerouslySetInnerHTML={{ __html: html }}
            ></Row>
          </Col>
        </Row>
      ) : (
        <>
          <Row justify="center">
            <Col
              xs={{ span: 22 }}
              md={{ span: 22 }}
              lg={{ span: 13 }}
              style={{
                fontSize: 16,
                color: "darkgrey",
                paddingLeft: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Skeleton active />
              <Skeleton.Image
                active
                style={{ width: 500, height: 500, margin: 50 }}
              />
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
