import { Col, Row, Card } from "antd";
import { relative } from "path";
import { useParams, useNavigate } from "react-router";

export default function Products(props) {
  const navigate = useNavigate();
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return (
    <Row justify="center">
      <Col xs={{ span: 22 }} md={{ span: 22 }} lg={{ span: 12 }}>
        <Row gutter={16} justify="start">
          {props.data &&
            props.data.map((e) => {
              return (
                <Col
                  style={{ marginBottom: 20 }}
                  onClick={() =>
                    navigate(
                      `/blog/${e.properties.Name.title[0].plain_text
                        .replace(/ /g, "")
                        .replace("?", "")}?id=${e.id}`
                    )
                  }
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                >
                  <Col
                    style={{
                      height: 170,
                      padding: 0,
                      borderRadius: 5,
                      marginBottom: 5,
                      overflow: "hidden",
                      backgroundImage: `url(${e.properties.image.files[0].name})`,
                      backgroundSize: "cover",
                      cursor: "pointer",
                    }}
                  ></Col>
                  <span
                    style={{
                      color: "grey",
                    }}
                  >
                    {new Date(e.last_edited_time).toLocaleDateString(
                      "it-IT",
                      options
                    )}
                  </span>
                  <p
                    style={{
                      marginTop: 5,
                      fontSize: 20,
                      color: "Black",
                      cursor: "pointer",
                    }}
                  >
                    {e.properties.Name.title[0].plain_text}
                  </p>
                </Col>
              );
            })}
        </Row>
      </Col>
    </Row>
  );
}
