import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { Col, Row, Input, Button, Timeline, message } from "antd";
import {
  ArrowRightOutlined,
  PhoneOutlined,
  PushpinOutlined,
  MailOutlined,
  InstagramOutlined,
  FacebookOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";

export default function Contact() {
  const { TextArea } = Input;
  const [messageApi, contextHolder] = message.useMessage();
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    console.log(form.current);

    emailjs
      .sendForm(
        "service_n5b74tj",
        "template_7fijs0a",
        form.current,
        "mZGPImeskIvZtRRrF"
      )
      .then(
        (result) => {
          window.scrollTo(0, 0);
          console.log(result.text);
          messageApi.open({
            type: "success",
            content: "Contatto inviato",
          });
        },
        (error) => {
          console.log(error.text);
          messageApi.open({
            type: "error",
            content: "Errore messaggio non inviato",
          });
        }
      );
  };

  return (
    <>
      {contextHolder}
      <Row
        gutter={16}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
          overflowX: "hidden",
          marginBottom: 100,
          marginTop: 50,
        }}
      >
        <Col xs={{ span: 22 }} md={{ span: 14 }} lg={{ span: 8, offset: 2 }}>
          <Col>
            <h2 style={{ fontSize: 38 }}>Contatti</h2>
            <h3 style={{ color: "#5e5e5e", lineHeight: 2 }}>
              Se pensi di aver bisogno di un{" "}
              <mark style={{ backgroundColor: "#C0FFC8" }}>supporto</mark> , di
              una <mark style={{ backgroundColor: "#C0FFC8" }}>consulenza</mark>{" "}
              , se hai qualche dubbio o vuoi avere informazioni più
              approfondite, contattami, sarò a tua disposizione! Mi puoi
              contattare per fissare un appuntamento in presenza oppure una
              consulenza online utilizzando il form qui sotto oppure utilizzando
              le informazioni di contatto riportate nella pagina
            </h3>
          </Col>
          <Col style={{ marginTop: 50, padding: 0 }}>
            <form
              style={{ display: "flex", flexDirection: "column" }}
              ref={form}
              onSubmit={sendEmail}
            >
              <label>Nome</label>
              <input
                style={{
                  border: "1px solid #ccc",
                  borderRadius: 5,
                  padding: 15,
                  boxSizing: "border-box",
                  marginTop: 10,
                  marginBottom: 20,
                }}
                type="text"
                placeholder="Tuo nome"
                name="user_name"
              />
              <label>Email</label>
              <input
                placeholder="Email"
                style={{
                  border: "1px solid #ccc",
                  borderRadius: 5,
                  padding: 15,
                  boxSizing: "border-box",
                  marginTop: 10,
                  marginBottom: 20,
                }}
                type="email"
                name="user_email"
              />
              <label>Messaggio</label>
              <textarea
                placeholder="Messaggio"
                style={{
                  border: "1px solid #ccc",
                  borderRadius: 5,
                  padding: 15,
                  height: 150,
                  boxSizing: "border-box",
                  marginTop: 10,
                  marginBottom: 20,
                }}
                name="message"
              />

              <Row
                style={{
                  marginTop: 20,
                  fontSize: 18,
                  color: "#1979FF",
                  cursor: "pointer",
                }}
              >
                <input
                  style={{
                    border: "none",
                    textAlign: "right",
                    color: "#1979FF",
                    marginRight: 5,
                    backgroundColor: "transparent",
                    width: "fit-content",
                    cursor: "pointer",
                  }}
                  type="submit"
                  value="Invia il tuo messaggio"
                />
                <ArrowRightOutlined />
              </Row>
            </form>
          </Col>
        </Col>
        <Col
          style={{ marginTop: 50 }}
          xs={{ span: 22 }}
          md={{ span: 14 }}
          lg={{ span: 8, offset: 1 }}
        >
          <Row gutter={16} align="start" justify="start">
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <Row
                style={{
                  flexDirection: "column",
                }}
              >
                <a target="_blank" href="https://goo.gl/maps/QbL8oiGU4M1idxp26">
                  <PushpinOutlined /> Via Astorre Lanari, 34, 35129 Padova
                </a>
                <a href="tel://+39 320 326 9054">
                  <PhoneOutlined /> +39 320 326 9054
                </a>
                <a href="mailto://silvia.mattiello.psi@gmail.com">
                  <MailOutlined /> silvia.mattiello.psi@gmail.com
                </a>

                <a
                  target="_blank"
                  href="https://instagram.com/silviamattiellopsicologa?igshid=YmMyMTA2M2Y="
                >
                  <InstagramOutlined /> @silviamattiellopsicologa
                </a>
                <a
                  target="_blank"
                  href="https://m.facebook.com/100089803483685/"
                >
                  <FacebookOutlined /> @Silvia Mattiello
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/silvia-mattiello-ab1365111"
                >
                  <LinkedinOutlined /> @Silvia Mattiello
                </a>
              </Row>
            </Col>
            <Col
              xs={{ span: 24 }}
              lg={{ span: 6 }}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <h3 style={{ marginTop: 20 }}>Orari:</h3>
              <Timeline
                style={{ marginTop: 10, width: 180, color: "black" }}
                mode="left"
              >
                <Timeline.Item color="green">Lunedì 9:00 -20:00</Timeline.Item>
                <Timeline.Item color="green">Martedì 9:00 -20:00</Timeline.Item>
                <Timeline.Item color="green">
                  Mercoledì 9:00 -20:00
                </Timeline.Item>
                <Timeline.Item color="green">Giovedì 9:00 -20:00</Timeline.Item>
                <Timeline.Item color="green">Venerdì 9:00 -20:00</Timeline.Item>
                <Timeline.Item color="grey">Sabato 9:00 -13:00</Timeline.Item>
                <Timeline.Item color="red">Domenica Chiuso</Timeline.Item>
              </Timeline>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
